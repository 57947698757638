<template>
  <ClubTemplate :clubId="clubId">
    <div class="container">
      <div class="page-header">
        <div class="page-header__ell">
          <h1 class="page-title">Редактировать новость</h1>
        </div>
      </div>
      <ClubNewsAddForm :formData="news" ref="clubNewsEditForm" />
      <form class="form">
        <div class="btn-gp">
          <b-button size="lg" variant="danger" @click="save">Сохранить</b-button>
          <router-link :to="{name: this.$routeName.CLUB_NEWS_LIST}" class="btn btn-link btn-lg">Отмена</router-link>
        </div>
      </form>
    </div>
  </ClubTemplate>
</template>

<script>
import ClubTemplate from './_component/ClubTemplate.vue';
import ClubNewsAddForm from "@component/Form/ClubNewsAddForm";

export default {
  components: {
    ClubTemplate,
    ClubNewsAddForm
  },
  props: {
    clubId: {
      type: [String, Number],
      required: true
    },
    newsId: [String, Number]
  },
  data() {
    return {
      news: {},
    };
  },
  methods: {
    async save() {
      let formData = this.$refs.clubNewsEditForm.formSubmitGetData();
      if(!formData) { return; }

      let sendData = Object.assign({}, formData);
      RequestManager.Club.updateClubNews({ clubId: this.clubId, newsId: this.newsId,  news: sendData }).then( (res) => {
        this.$toasts.push({
          message: 'Новость успешно обновлена',
          duration: 4000,
        });
        this.$router.push({name: this.$routeName.CLUB_NEWS_LIST });
      });
    }
  },
  mounted() {
    RequestManager.Club.getClubNewsById({
      clubId: this.clubId,
      newsId: this.newsId,
    }).then((news) => {
      this.news = Object.assign({}, news);
    });
  }
};
</script>
